<template>
  <q-form ref="formRef" greedy>
    <div>
      <table class="table-user-info">
        <tr>
          <td>{{ $t('label.register.input_your_email') }}</td>
          <td>{{ userInfo.email }}</td>
        </tr>
      </table>
    </div>
    <q-input
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || requiredRule.input_your_name]"
      outlined
      v-model="form.display_name"
      dense
      :placeholder="$t('label.register.input_your_name')"
    >
      <template v-slot:prepend>
        <q-icon name="account_circle" />
      </template>
    </q-input>

    <q-input
      type="password"
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || requiredRule.password]"
      outlined
      v-model="form.new_password"
      dense
      autocomplete="input_your_password"
      :placeholder="$t('label.register.input_your_password')"
    >
      <template v-slot:prepend>
        <q-icon name="vpn_key" />
      </template>
    </q-input>

    <q-input
      type="password"
      lazy-rules
      :rules="[(val) => (val && val.length > 0 && val === form.new_password) || requiredRule.password_again]"
      outlined
      v-model="form.confirm_password"
      dense
      autocomplete="confirm_your_password"
      :placeholder="$t('label.register.confirm_your_password')"
    >
      <template v-slot:prepend>
        <q-icon name="lock_open" />
      </template>
    </q-input>
    <q-footer class="bg-grey-2 q-py-sm q-mx-xs">
      <q-toolbar class="q-gutter-sm">
        <q-space />
        <q-btn no-caps class="btn-cancel" @click="onCancel">
          {{ $t('cancel') }}
        </q-btn>
        <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
      </q-toolbar>
    </q-footer>
  </q-form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import { UserModel } from '@/models/user-model'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class ProfileSettings extends Vue {
  form = {
    display_name: '',
    new_password: '',
    confirm_password: '',
  }

  get requiredRule() {
    const requiredRule = {
      input_your_name: this.$t('validate.please_input_your_name'),
      email: this.$t('validate.please_input_your_valid_email'),
      password: this.$t('validate.please_input_your_valid_password'),
      password_again: this.$t('validate.please_input_your_valid_password_again'),
    }
    return requiredRule
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  onCancel() {
    this.$router.push({ name: 'home' })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    success = await dispatch(ACTION_PROFILE.UPDATE, {
      ...this.form,
    })

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.onCancel()
    }
  }

  async created() {
    this.form.display_name = this.userInfo.display_name ?? ''
  }
}
</script>
<style lang="scss" scoped>
.table-user-info {
  td {
    font-size: 13px;
    color: #151b26;
    padding: 5px 0px 5px 0px;
    &:nth-child(1) {
      font-weight: bold;
      padding-right: 10px;
      &::after {
        content: ':';
      }
    }
  }
}
</style>
