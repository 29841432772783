<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    title="Treats"
    :rows="users"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th :props="props" key="is_active" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.audience_user.is_active') }} </span></q-th
        >
        <q-th key="action" rowspan="2" class="text-left" style="width: 140px"></q-th>
        <q-th :props="props" key="display_name" rowspan="2"
          ><span class="text-bold">{{ $t('label.register.input_your_name') }}</span></q-th
        >
        <q-th :props="props" key="email" rowspan="2"
          ><span class="text-bold">{{ $t('label.register.input_your_email') }}</span></q-th
        >
        <q-th :props="props" key="app_names" rowspan="2"
          ><span class="text-bold">{{ $t('label.apps') }}</span></q-th
        >
        <q-th :props="props" key="updated_at" rowspan="2"
          ><span class="text-bold">{{ $t('label.updated_at') }}</span></q-th
        >
        <q-th :props="props" key="last_logged_in" rowspan="2"
          ><span class="text-bold">{{ $t('label.last_logged_in') }}</span></q-th
        >
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td key="is_active" :props="props">
          <q-toggle v-model="props.row.is_active" color="primary" @click="handleToggleActive(props.row)" />
        </q-td>
        <q-td key="action" :props="props.index">
          <q-btn
            size="sm"
            flat
            round
            icon="edit"
            @click="onEdit(props.row)"
            class="q-mr-sm"
            v-if="!props.row.is_super"
          />
          <q-btn
            size="sm"
            flat
            round
            icon="content_copy"
            @click="onCopy(props.row)"
            class="q-mr-sm"
            v-if="!props.row.is_super"
          />
          <q-btn
            size="sm"
            flat
            round
            color="red"
            icon="delete"
            @click="onDelete(props.row)"
            class="q-mr-sm"
            v-if="!props.row.is_super"
          />
        </q-td>
        <q-td key="display_name" :props="props">{{ props.row.display_name }} </q-td>
        <q-td key="email" :props="props">{{ props.row.email }} </q-td>
        <q-td>
          <q-chip color="primary" text-color="white" v-for="(name, index) in props.row.app_names" :key="index">
            {{ name }}
          </q-chip>
        </q-td>
        <q-td key="updated_at" :props="props">{{ props.row.updated_at }} </q-td>
        <q-td key="last_logged_in" :props="props">{{ props.row.last_logged_in }} </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.users.user_list') }}</h2>
      <q-space />
      <q-btn no-caps class="btn-save" @click="onAdd" :label="$t('add_new')" />
    </template>
  </q-table>

  <UserFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedUser"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IUser } from '@/utils/types'
import { ACTION_USER } from '@/store/actions'
import UserMixin from '@/components/user/mixins/UserMixin.vue'
import UserFormModal from '@/components/user/UserFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Watch } from 'vue-property-decorator'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    UserMixin,
    UserFormModal,
    CLoading,
  },
  directives: { maska },
})
export default class UserList extends mixins(UserMixin, BaseFormMixin) {
  selectedUser: IUser = {}
  modalVisible = false
  reLoad = false
  loading = true
  users: IUser[] = []

  get userInfo(): IUser {
    return this.$store.getters.userInfo
  }

  @Watch('$store.getters.users')
  updateUsers() {
    this.users = cloneDeep(this.$store.getters.users)
  }

  async onCopy(data: IUser) {
    this.modalVisible = true
    const userCopy = cloneDeep(data) as IUser
    userCopy._id = ''
    userCopy.display_name = userCopy.display_name + ' Copy'
    this.selectedUser = userCopy
  }

  onAdd() {
    this.modalVisible = true
    this.selectedUser = {}
  }

  onCloseModal() {
    this.reLoad = true
    this.modalVisible = false
  }

  onEdit(data: IUser) {
    this.selectedUser = data
    this.modalVisible = true
  }

  async handleToggleActive(record: IUser) {
    const isSuccess = await this.$store.dispatch(ACTION_USER.UPDATE, record)
    if (!isSuccess) {
      record.is_active = false
    } else {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
  }

  async created() {
    await this.$store.dispatch(ACTION_USER.LOAD_ITEMS)
    this.loading = false
  }
}
</script>
