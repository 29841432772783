<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title> {{ modalTitle }} </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <div class="max-height-dialog">
          <q-form ref="formRef" greedy>
            <label class="text-grey-9 text-bold">{{ $t('label.register.input_your_name') }} </label>
            <q-input
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || requiredRule.input_your_name]"
              outlined
              v-model="form.display_name"
              dense
              :placeholder="$t('label.register.input_your_name')"
            >
              <template v-slot:prepend>
                <q-icon name="account_circle" />
              </template>
            </q-input>

            <label class="text-grey-9 text-bold">{{ $t('label.register.input_your_email') }} </label>
            <q-input
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || requiredRule.email]"
              outlined
              v-model="form.email"
              autocomplete="new-email"
              dense
              :placeholder="$t('label.register.input_your_email')"
            >
              <template v-slot:prepend>
                <q-icon name="email" />
              </template>
            </q-input>

            <q-input
              type="password"
              lazy-rules
              outlined
              v-model="form.password"
              :rules="[(val) => (val && val.length > 0) || requiredRule.password]"
              dense
              autocomplete="new-password"
              :placeholder="$t('label.register.input_your_password')"
            >
              <template v-slot:prepend>
                <q-icon name="vpn_key" />
              </template>
            </q-input>
          </q-form>
        </div>
        <div class="row no-wrap items-center q-mt-md">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps class="btn-cancel" @click="onCancel">{{ $t('cancel') }} </q-btn>
            <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_USER } from '@/store/actions'
import { IUser } from '@/utils/types'
import UserMixin from '@/components/user/mixins/UserMixin.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { UserMixin },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class UserFormModal extends mixins(UserMixin, BaseFormDialogMixin) {
  @Prop()
  data!: IUser

  get requiredRule() {
    const requiredRule = {
      input_your_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.register.input_your_name'),
      }),
      email: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.register.input_your_email'),
      }),
      password: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.register.input_your_password'),
      }),
    }
    return requiredRule
  }

  files: File[] = []

  form: IUser = {
    _id: '',
    email: '',
    password: '',
    is_sendmail_password: false,
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.users.add_new_user') : this.$t('label.users.edit_user')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let user = null
    if (this.isNew) {
      this.form.is_active = true
      user = await dispatch(ACTION_USER.ADD_NEW, {
        ...this.form,
      })
    } else {
      user = await dispatch(ACTION_USER.UPDATE, {
        ...this.form,
      })
    }
    if (user) {
      this.$emit('update:onSelect', user)
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>
