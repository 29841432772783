import { render } from "./ProfileSettings.vue?vue&type=template&id=82688b36&scoped=true"
import script from "./ProfileSettings.vue?vue&type=script&lang=ts"
export * from "./ProfileSettings.vue?vue&type=script&lang=ts"

import "./ProfileSettings.vue?vue&type=style&index=0&id=82688b36&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-82688b36"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QIcon,QFooter,QToolbar,QSpace,QBtn});
